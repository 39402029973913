import React from "react";
import { awsUrl2, awsUrl } from "../../config";
import axios from "axios";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import LoadingOverlay from "react-loading-overlay";
import * as XLSX from 'xlsx';
import moment from "moment";
// let deletImg = require("../../images/trash.png")
export class DownloadDashboardFiles extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dashboardFiles: this.props.dashboardFiles || [],
      preUwFiles: this.props.preUwFiles || [],
      fileListToStore: this.props.fileListToStore || [],
      downloadInProgress: false,
      summaryData: this.props.summaryData,
      companyName: this.props.companyName
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      dashboardFiles: nextProps.dashboardFiles || [],
      preUwFiles: nextProps.preUwFiles || [],
      fileListToStore: nextProps.fileListToStore || [],
    });
  }

  downloadFile = async (file) => {
    try {
      this.setState({ downloadInProgress: true });
      await axios
        .post(
          awsUrl2 + "/api/dashboardFileDownload",
          JSON.stringify({
            fileName: file,
          })
        )
        .then(async (res) => {
          let downloadURL = res.data;

          let a = document.createElement("a");
          a.download = file.split("/").reverse()[0];
          a.href = downloadURL;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          this.setState({ downloadInProgress: false });
        });
    } catch (error) {
      this.setState({ downloadInProgress: false });
    }
  };

  downloadAllFiles = async (e) => {
    e.preventDefault();
    try {
      this.setState({ downloadInProgress: true });
      let { dashboardFiles, preUwFiles, fileListToStore } = this.state;
      var zip = new JSZip();
      const folder = zip.folder("files");
      for (let file of dashboardFiles) {
        await axios
          .post(
            awsUrl2 + "/api/dashboardFileDownload",
            JSON.stringify({
              fileName: file,
            })
          )
          .then(async (res) => {
            let downloadURL = res.data;
            let responseFileData = await axios.get(downloadURL, {
              responseType: "blob",
            });

            folder.file(file.split("/").reverse()[0], responseFileData.data);
          });
      }

      for (let file of [...preUwFiles, ...fileListToStore]) {
        await axios
          .post(
            awsUrl + "/api/uwDashboardFileDownload",
            JSON.stringify({
              fileName: file,
            })
          )
          .then(async (res) => {
            let downloadURL = res.data;
            let responseFileData = await axios.get(downloadURL, {
              responseType: "blob",
            });

            folder.file(file.split("/").reverse()[0], responseFileData.data);
          });
      }
      zip
        .generateAsync({ type: "blob" })
        .then((blob) => {
          saveAs(blob, "AffinityFiles.zip");
          this.setState({ downloadInProgress: false });
        })
        .catch((e) => console.log(e));
    } catch (error) {
      console.log("erorr: ", error);
      this.setState({ downloadInProgress: false });
    }
  };

  //function to download files from fourth specific bucket
  downloadFileFourth = async (file) => {
    try {
      this.setState({ downloadInProgress: true });
      await axios
        .post(
          awsUrl + "/api/uwDashboardFileDownload",
          JSON.stringify({
            fileName: file,
          })
        )
        .then(async (res) => {
          let downloadURL = res.data;

          let a = document.createElement("a");
          a.download = file.split("/").reverse()[0];
          a.href = downloadURL;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          this.setState({ downloadInProgress: false });
        });
    } catch (error) {
      this.setState({ downloadInProgress: false });
    }
  };

  deleteFile = async (indx) => {
    let { fileList } = this.state;
    this.setState({ downloadInProgress: true });
    let removedFile = fileList[indx];
    fileList.splice(indx, 1);
    //api call to update fileList in DB.
    await axios
      .post(
        awsUrl2 + "/api/deleteFilefromS3",
        JSON.stringify({ key: removedFile })
      )
      .then(async (res) => {
        await axios
          .post(
            awsUrl2 + `/api/updateSolveTrackingDataGeneric/generic`,
            JSON.stringify({
              domain: this.props.domainName,
              dashboardFiles: fileList,
            })
          )
          .then((res) => {
            // this.props.updateDomainMessageidFiles(this.props.domainName, 'dashboardFiles', fileList);
            this.setState({ fileList, downloadInProgress: false });
          });
      })
      .catch((err) => {
        console.log("error in delete file from s3 API");
      });
  };

  downloadSummaryData = async (key) => {
    try {
 
      this.setState({ downloadInProgress: true });

      await axios
      .post(
        awsUrl + "/api/uwDashboardFileDownload",
        JSON.stringify({
          fileName: key,
          dataType: "summary"
        })
      )
      .then(async (res) => {

        let downloadURL = res.data;

        let filename = `${this.props.companyName}_LossRunSummary.xlsx`
        const dataone = await axios.get(downloadURL);

        const modifiedData = dataone.data.map(item => {
          // Modify the date fields to remove timestamp
          if (item.valuation_date) {
            item.valuation_date = moment(item.valuation_date).format('YYYY-MM-DD');
          }
          if (item.eff_date) {
            item.eff_date = moment(item.eff_date).format('YYYY-MM-DD');
          }
          if (item.exp_date) {
            item.exp_date = moment(item.exp_date).format('YYYY-MM-DD');
          }
    
          // Return the modified item
          return item;
        });
        
        const workbook = XLSX.utils.book_new();

        // Convert JSON data to a worksheet
        const worksheet = XLSX.utils.json_to_sheet(modifiedData);
    
        // Append the worksheet to the workbook
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    
        // Write the workbook to a file
        XLSX.writeFile(workbook, filename);
        this.setState({ downloadInProgress: false });
      })
      
    } catch (error) {
      console.log('error', error)
      this.setState({ downloadInProgress: false });
    }
  }

  render() {
    let { preUwFiles, fileListToStore, dashboardFiles, downloadInProgress, summaryData, companyName } =
    this.state;

    let filesTotal =
    (fileListToStore?.length || 0) +
    (dashboardFiles?.length || 0) +
    (preUwFiles?.length || 0) +
    (summaryData ? 1 : 0);
    
    return (
      <>
        {filesTotal > 0 ? (
          <div className="download-files">
            <div className="upload-heading my-3 mx-auto text-center">
              {summaryData && (
                     <div className="text-center"> {/* Use a div to center the button */}
                     <button
                       className="btn btn-link w-100 fileNameBtn" // Removed text-start
                       onClick={() => this.downloadSummaryData(summaryData)}
                     >
                       {companyName}_LossRunSummary.xlsx
                     </button>
                   </div>
              )}
             
              {filesTotal > 1 &&
              <button
                className="btn btn-link downloadFilesBtn"
                onClick={(e) => this.downloadAllFiles(e)}
              >
                Download All
              </button>
  }
            </div>
            <LoadingOverlay active={downloadInProgress} spinner>
              <div className="my-0 mx-auto">
                {filesTotal > 0 && (
                  <table style={{ marginInline: "auto" }}>
                    <tbody>
                      {dashboardFiles?.length > 0 &&
                        dashboardFiles.map((file, indx) => {
                          return (
                            <tr>
                              <td>
                                <button
                                  className="btn btn-link w-100 text-start"
                                  onClick={() => this.downloadFile(file)}
                                >
                                  {file.split("/").pop()}
                                </button>
                              </td>
                              {/* <td>
                      <button className="btn btn-link w-100 text-start" onClick={() => this.deleteFile(indx)}>
                        <img src={deletImg} alt="delete button" style={{ width: "25px" }} />
                      </button>
                    </td> */}
                            </tr>
                          );
                        })}
                      {fileListToStore?.length > 0 &&
                        fileListToStore.map((file, indx) => {
                          return (
                            <tr>
                              <td>
                                <button
                                  className="btn btn-link w-100 text-start fileNameBtn"
                                  onClick={() => this.downloadFileFourth(file)}
                                >
                                  {file.split("/").pop()}
                                </button>
                              </td>
                              {/* <td>
                      <button className="btn btn-link w-100 text-start" onClick={() => this.deleteFile(indx)}>
                        <img src={deletImg} alt="delete button" style={{ width: "25px" }} />
                      </button>
                    </td> */}
                            </tr>
                          );
                        })}
                      {preUwFiles?.length > 0 &&
                        preUwFiles.map((file, indx) => {
                          return (
                            <tr>
                              <td>
                                <button
                                  className="btn btn-link w-100 text-start"
                                  onClick={() => this.downloadFileFourth(file)}
                                >
                                  {file.split("/").pop()}
                                </button>
                              </td>
                              {/* <td>
                      <button className="btn btn-link w-100 text-start" onClick={() => this.deleteFile(indx)}>
                        <img src={deletImg} alt="delete button" style={{ width: "25px" }} />
                      </button>
                    </td> */}
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                )}
              </div>
            </LoadingOverlay>
          </div>
        ) : (
          <div className="upload-heading my-3 mx-auto text-center">
            {" "}
            <h3>No Files Uploaded!</h3>
          </div>
        )}
      </>
    );
  }
}
