import React, { useEffect, useState } from "react";
import SmallLoader from "../../common/SmallLoader";
import { createPricingSummaryTable } from "../sections-quote/pricing-tool/utils/utils";
import DatePicker from "react-datepicker";
import moment from "moment";
const PricingTableSummary = ({
  styles,
  sutaTypeMap,
  data,
  pricingTablesummaryDataLoader,
  selectedCarrier,
  proposalNumber,
  editedCompanyNameViewProposal,
  setEditedCompanyNameViewProposal,
  editedquoteGenratedDate,
  seteditedquoteGenratedDate,
  companyNameError
}) => {

  let initialQuoteDate = moment.unix(editedquoteGenratedDate / 1000).format("MM/DD/YYYY")
  let newDate = new Date(initialQuoteDate)
  const [newDateee, setNewDatee] = useState(null);

  const handleDateChange = (date) => {
    if (date) {
      const year = date.getFullYear();
      if (year.toString().length <= 4) {
        setNewDatee(date);
        seteditedquoteGenratedDate(date);
      } else {
        // Optionally, handle the situation (e.g., alert the user)
        console.warn("Year must be four digits.");
      }
    }
  };

  return (
    <>
      <div>
        <div className="d-flex justify-content-between mt-5 mb-3">
          <h5 className="fw-bold">Pricing Summary</h5>
        </div>
        {!pricingTablesummaryDataLoader && (
          <div className="d-flex justify-content-between ">
            <div className="d-flex" style={{ width: "80%" }}>
              <p className="pr-3 text-left " style={{ marginRight: "1rem", width: "30%" }}>
                <span className="quoteDatepicker">
                  <b>Quote Date :</b>{" "}
                  <DatePicker
                    selected={newDate}
                    onChange={handleDateChange}
                    showYearDropdown
                    showMonthDropdown
                    useShortMonthInDropdown
                    dateFormat="MM/dd/yyyy"
                  />
                </span>
              </p>
              <p className="pr-3 text-left" style={{ width: "80%" }}>
                <span>
                  <b>Pricing Indication created for :</b>{" "}
                  <input
                    style={{ width: "55%", height: "70%" }}
                    value={editedCompanyNameViewProposal || ""}
                    onChange={(e) => setEditedCompanyNameViewProposal(e.target.value)}
                    maxLength={49}
                  />
                </span>
              </p>
            </div>
            <div className="text-right d-flex">
              <p>
                <span>
                  <b>Proposal # :</b> {proposalNumber}
                </span>
              </p>
            </div>
          </div>
        )}
        {companyNameError && (
          <div>
            {/* Your content here for when the word count exceeds 60 */}
            <p className={`${styles.dynamicName} dynamicName `}>Company name exceeds 48 characters.</p>
          </div>
        )}
        {!pricingTablesummaryDataLoader && (
          <>
            <div className={`${styles.tableContainer} tableContainer`}>
              {createPricingSummaryTable(data?.[selectedCarrier], styles)}
            </div>

            <div className="mt-5 mb-5">
              {sutaTypeMap?.peoStates?.length > 0 && (
                <p
                  className="fw-bold d-flex justify-content-center"
                  style={{ fontSize: "12px" }}
                >
                  *** {sutaTypeMap.peoStates.join(", ")} - Total Burden includes
                  Workers Compensation and Risk Management Administration (Bill
                  Rate) plus FICA/Medicare and FUTA ***
                </p>
              )}
              {sutaTypeMap?.clientStates?.length > 0 && (
                <p
                  className="fw-bold d-flex justify-content-center"
                  style={{ fontSize: "12px" }}
                >
                  *** {sutaTypeMap.clientStates.join(", ")} - States SUTA will
                  be calculated, reported and paid with clients SUTA
                  information, total burden rate above does not include SUTA ***
                </p>
              )}
            </div>
          </>
        )}

        {pricingTablesummaryDataLoader && (
          <div className="d-flex justify-content-center">
            <SmallLoader />
          </div>
        )}
      </div>
    </>
  );
};

export default React.memo(PricingTableSummary);
