import React, { useState, useEffect } from "react";
import { awsUrl2 } from "../../../config";
import { Auth } from "aws-amplify";
import axios from "axios";

// Define the component
const DownloadEAcord = (props) => {
  // State declaration using useState hook
  const { selectedCarrier, stateTableData, updatePopUpData } = props;
  const states = Object.keys(stateTableData);
  const [loadingEAcordNCCI, setLoadingEAcordNCCI] = useState(false);
  const [loadingEAcordCA, setLoadingEAcordCA] = useState(false);
  const [loadingEAcordFL, setLoadingEAcordFL] = useState(false);
  const not_include_in_ncci = ["oh", "wa", "wy", "nd", "fl", "ca"];
  const [statesMap, setStatesMap] = useState({
    ncci: {
      available: false,
      stateList: [],
    },
    ca: {
      available: false,
      stateList: [],
    },
    fl: {
      available: false,
      stateList: [],
    },
  });

  useEffect(() => {
    const result = JSON.parse(JSON.stringify(statesMap));
    states.forEach((state) => {
      switch (state) {
        case "ca":
          result.ca.stateList.push(state.toUpperCase());
          result.ca.available = true;
          break;
        case "fl":
          result.fl.stateList.push(state.toUpperCase());
          result.fl.available = true;
          break;
        default:
          if (!not_include_in_ncci.includes(state)) {
            result.ncci.stateList.push(state.toUpperCase());
            result.ncci.available = true;
          }
          break;
      }
    });
    console.log("setStatesMap", states, result);
    setStatesMap(result);
  }, [stateTableData, selectedCarrier]);

  const handleDownloadPopup = async (acordType) => {
    updatePopUpData({
      show: true,
      title: "Note",
      children: (
        <>
          <br></br>
          <span style={{ fontWeight: "bold" }}>
            Eacord may not work as intended in other browsers. For the best
            experience, please use Google Chrome.
          </span>
        </>
      ),
      acceptBtn: "Ok",
      acceptBtnCallback: async () => {
        try {
          await downloadEAcord(acordType);
        } catch (error) {
          console.log("error: ", error);
        }
      },
      disableNoButton: true,
    });
  };
  const downloadEAcord = async (acordType) => {
    if (acordType == "ca") setLoadingEAcordCA(true);
    if (acordType == "ncci") setLoadingEAcordNCCI(true);
    if (acordType == "fl") setLoadingEAcordFL(true);
    let quoteData = JSON.parse(sessionStorage.getItem("quoteData"));
    let isLoggedIn = await Auth.currentAuthenticatedUser();
    let emailID = isLoggedIn.attributes.email;
    let address = sessionStorage.getItem("address");
    const carrier = selectedCarrier;
    let currProspectDetails = JSON.parse(
      sessionStorage.getItem("currProspect")
    );
    let etQuoteDetails = {
      email: sessionStorage.getItem("user_id"),
      timestamp: quoteData.date,
      carrier,
      mail_address: "",
      address: address,
      state: statesMap[acordType].stateList,
      peo: currProspectDetails?.peoDetails?.selectedPeo,
      producerDetails: {
        producerName:
          currProspectDetails?.["companyProfile"]?.["producer"]?.["value"] ||
          "Paul Hughes",
        producerEmail: emailID,
        producerFax: " ",
        producerPhone: " ",
        producerMobile: " ",
      },
    };
    axios
      .post(
        awsUrl2 + `/api/generateEAcord${acordType.toUpperCase()}`,
        JSON.stringify(etQuoteDetails)
      )
      .then(async (res) => {
        const filename = `editable_acord_${acordType.toUpperCase()}_${carrier}.pdf`;
        if (typeof res.data === "string" && res.data.startsWith("http")) {
          const signedUrl = res.data;
          const response = await fetch(signedUrl);
          const blob = await response.blob();

          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = filename;

          document.body.appendChild(link);
          link.click();

          document.body.removeChild(link);
          console.log("signedUrl", signedUrl);
          await axios.delete(awsUrl2 + `/api/deleteEAcord?url=${signedUrl}`);
        }
        if (acordType == "ca") setLoadingEAcordCA(false);
        if (acordType == "ncci") setLoadingEAcordNCCI(false);
        if (acordType == "fl") setLoadingEAcordFL(false);
      });
  };

  // Component JSX
  return (
    <div>
      {/* <h1>Hello, World!</h1> */}
      <div className="btnAlignDow">
        {statesMap["ncci"].available && (
          <button
            className="btn btn-warning btn-lg btn-warning-shadow btnSubmits"
            onClick={() => handleDownloadPopup("ncci")}
            disabled={!selectedCarrier || loadingEAcordNCCI}
          >
            {loadingEAcordNCCI
              ? "Downloading NCCI EAcord..."
              : "Download NCCI EAcord"}
          </button>
        )}
        {statesMap["ca"].available && (
          <button
            className="btn btn-warning btn-lg btn-warning-shadow btnSubmits"
            onClick={() => handleDownloadPopup("ca")}
            disabled={!selectedCarrier || loadingEAcordCA}
          >
            {loadingEAcordCA
              ? "Downloading CA EAcord..."
              : "Download CA EAcord"}
          </button>
        )}
        {statesMap["fl"].available && (
          <button
            className="btn btn-warning btn-lg btn-warning-shadow btnSubmits"
            onClick={() => handleDownloadPopup("fl")}
            disabled={!selectedCarrier || loadingEAcordFL}
          >
            {loadingEAcordFL
              ? "Downloading FL EAcord..."
              : "Download FL EAcord"}
          </button>
        )}
      </div>
    </div>
  );
};

// Export the memoized component
export default React.memo(DownloadEAcord);
