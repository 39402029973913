import React, { Component } from "react";
import { signIn } from "../../../utils/log_in";
import {
  clearErr,
  validEmail,
  validPassword,
  changeText,
} from "../../../utils/common";

class FormLogin extends Component {
  render() {
    return (
      <div id="lgin">
        <div id="formLogin">
          {/* <p className="formTitle text-center">LOGIN</p> */}
          {/* <hr /> */}
          <form onSubmit={(e) => signIn(e, this.props.loginPageHandler)}>
            <div>
              <div className="mb-3 mt-3 lablestyle">Email</div>
              <div className="errMsg" />
              <input
                autoComplete="off"
                id="userId"
                type="email"
                placeholder="Enter Email Address"
                onBlur={validEmail}
                onChange={clearErr}
                className="input-field-login"
              />
            </div>
            <div>
              <div className="mt-3 mb-3 lablestyle">Password</div>
              <div className="errMsg" />
              <input
                autoComplete="off"
                id="userPassword"
                type="password"
                placeholder="Enter Password"
                onBlur={validPassword}
                onChange={clearErr}
                className="input-field-login"
              />
            </div>
            {/* <input
              autoComplete="off"
              id="doRemember"
              type="checkbox"
              defaultChecked
            />{" "} */}
            {/* <span className="inptSideText">Remember Me</span> */}
            <div className="formBottomLinks d-flex">
              <a
                id="link"
                data-toggle="tab"
                href="#chngpwd"
                onClick={() => {
                  this.props.loginPageHandler("changePassword");
                }}
                className="fontstylelink"
              >
                Change Password
              </a>{" "}
              <a
                data-toggle="tab"
                aria-expanded="true"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  this.props.loginPageHandler("resetPassword");
                }}
              >
                Forgot Password?
              </a>
            </div>
            <div className="btnclassalign">
              <span className="errMsg" />
              <button id="loginSubmit" className="btnFormFinal">
                <span style={{ fontWeight: "bolder", fontSize: "20px" }}>
                  Login
                </span>
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default FormLogin;
