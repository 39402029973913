import React, { Component } from "react";
import Allques from "../../subcompo/sections-quote/Allques";
import { setUWQues } from "../../../utils/session_data";
import { quesList } from "../../../utils/ques_list";
import { submitQues, updateQuesList } from "../../../utils/form_ques";
import $ from "jquery";
import NewProgress from "../../../newcomponent/common/NewProgress";

class FormUnderQues extends Component {
  constructor(props) {
    super(props);
    this.state = {
      quesList: quesList,
    };
    this.setUWQues = setUWQues.bind(this);
    this.updateQuesList = updateQuesList.bind(this);
    this.submitQues = submitQues.bind(this);
  }

  createQuestionsData = () => {
    let { quesList } = JSON.parse(JSON.stringify(this.state));
    for (let question of [...quesList]) {
      if (question.response === true) {
        question.response = "Y";
      } else if (question.response === false) {
        question.response = "N";
      }
    }

    return { quesList };
  };

  componentDidMount() {
    $("html, body").animate({ scrollTop: 0 }, 250);

    let currProspect = sessionStorage.getItem("currProspect");
    let acordUwQues = sessionStorage.getItem("acordUwQues");

    if (currProspect && JSON.parse(currProspect)?.uwQues) {
      currProspect = JSON.parse(currProspect);
      let _quesList = [];

      for (let ind in currProspect.uwQues) {
        _quesList.push({
          ...currProspect.uwQues[ind],
          response: currProspect.uwQues[ind].response === "Y" ? true : false,
        });
      }

      this.setState({ quesList: _quesList });
    } else if (acordUwQues) {
      acordUwQues = JSON.parse(acordUwQues);

      this.setState({ quesList: acordUwQues });
    }
  }

  setQuestions = (quesList) => {
    let response = {};
    for (let question of quesList) {
      response[question.name] = {
        ques: question.ques,
        response: question.response,
        remarks: question.remarks,
      };
    }

    return response;
  };

  render() {
    const { quesList, incompleteQuestions } = this.state;
    const { updatePopUpData } = this.props;

    let hideButtons = sessionStorage.getItem("hideButtons") ? true : false;

    let compName = "";
    try {
      let currProspect = JSON.parse(sessionStorage.getItem("currProspect"));
      if (
        currProspect &&
        currProspect.companyProfile &&
        currProspect.companyProfile.companyName
      )
        compName = currProspect.companyProfile.companyName.value;
    } catch (err) {
      console.log(err);
    }
    return (
      <div id="undrQues" className="container-fluid per98">
        <NewProgress />
        <div id="underWritingQues">
          <div
            className="compName"
            style={{
              fontSize: "20px",
              fontWeight: "600",
              color: "#2c2e33",
            }}
          >
            <p
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginRight: "0.5rem",
              }}
            >
              {compName}
            </p>
          </div>
          <form>
            <div className="row no-gutters heading-row">
              <div className="bg-white pink-header uw-heading-box">
                <span className="formTitle title font-family-montserrat-bold finallyquestionwidth headings">
                  Questions
                </span>
                <span className="title2 finallyquestionwidth compProHeading">
                  And finally, answers to some basic questions about your
                  business
                </span>
              </div>
            </div>
            <div className="table-responsive table-undrques-container">
              <Allques
                quesList={quesList}
                setUWQues={this.setUWQues}
                updateQuesList={this.updateQuesList}
              />
            </div>
            {incompleteQuestions && (
              <div className="errMsg text-center mb-4">
                Please answer all the questions!
              </div>
            )}
          </form>
          <div className="row ">
            <div className="col-12 text-center">
              <button
                type="button"
                className="btn  btn-warning btn-lg btn-warning-shadow btnSubmits"
                onClick={() => {
                  if (!hideButtons) this.submitQues(quesList, updatePopUpData);
                }}
                disabled={hideButtons}
              >
                Get Quote
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default FormUnderQues;
