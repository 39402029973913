import axios from "axios";
import React, { useEffect, useState } from "react";
import { awsUrl2 } from "../../config";
import LoadingOverlay from "react-loading-overlay";

function DealWonLostButtons(props) {
  const { rowData, isWon, isLost, isConfirmed, updateDealMap } = props;
  const [dealWon, setDealWon] = useState(isWon);
  const [dealLost, setDealLost] = useState(isLost);
  const [isLoading, setIsLoading] = useState(false);
  // console.log("rowData11", rowData);
  const handleClick = async (wonflag) => {
    setIsLoading(true);
    let { user_email_id, quoteData, address } = rowData;

    let body = {};

    if (wonflag) {
      if (dealWon) {
        body.deal_won = false;
        body.deal_lost = false;
      } else {
        body.deal_won = true;
        body.deal_lost = false;
      }
    } else {
      if (dealLost) {
        body.deal_won = false;
        body.deal_lost = false;
      } else {
        body.deal_won = false;
        body.deal_lost = true;
      }
    }
    try {
      let userRequestBody = {
        user_email_id,
        deal_won: body.deal_won,
        deal_lost: body.deal_lost,
      };
      let promiseList = [
        axios.post(awsUrl2 + "/api/userTableData/update", userRequestBody),
      ];
      if (quoteData) {
        let { uuid, date } = quoteData;
        let quoteRequestBody = {
          user_email_id,
          timestamp: date,
          peo: "ies",
          address,
          uuid,
          deal_won: body.deal_won,
          deal_lost: body.deal_lost,
        };
        promiseList.push(
          axios.post(awsUrl2 + "/api/updateUserStatusTable", quoteRequestBody)
        );
      }

      await Promise.all(promiseList).then((res) => {
        setIsLoading(false);

        updateDealMap(user_email_id, wonflag);
        if (wonflag) {
          if (dealWon) {
            setDealWon(false);
            setDealLost(false);
            // rowData.deal_won = false;
            // rowData.deal_lost = false;
          } else {
            setDealWon(true);
            setDealLost(false);
            // rowData.deal_won = true;
            // rowData.deal_lost = false;
          }
        } else {
          if (dealLost) {
            setDealWon(false);
            setDealLost(false);
            rowData.deal_won = false;
            rowData.deal_lost = false;
          } else {
            setDealWon(false);
            setDealLost(true);
            rowData.deal_won = false;
            rowData.deal_lost = true;
          }
        }
        // console.log("response from API: ", res);
        // setIsLoading(false);
      });
    } catch (e) {
      console.error("Error while updating", e);
    }
  };
  useEffect(() => {
    if (isWon !== dealWon) {
      setDealWon(isWon);
    }
  }, [isWon]);

  useEffect(() => {
    if (isLost !== dealLost) {
      setDealLost(isLost);
    }
  }, [isLost]);
  return (
    <LoadingOverlay active={isLoading}>
      <div style={{ display: "flex", justifyContent: "space-evenly" }}>
        {(dealWon || (!dealWon && !dealLost)) && (
          <button
            style={{
              fontSize: "12px",
              backgroundColor: "#2ea44f",
              borderRadius: "4px",
              border: "1px solid #2ea44f",
            }}
            onClick={() => {
              handleClick(true);
            }}
            disabled={isConfirmed}
          >
            Won
          </button>
        )}
        {(dealLost || (!dealWon && !dealLost)) && (
          <button
            style={{
              fontSize: "12px",
              backgroundColor: "#cc4d29",
              borderRadius: "4px",
              border: "1px solid #cc4d29",
            }}
            onClick={() => {
              handleClick(false);
            }}
            disabled={isConfirmed}
          >
            Lost
          </button>
        )}
      </div>
    </LoadingOverlay>
  );
}

export default React.memo(DealWonLostButtons);
