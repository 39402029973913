import React, {
  Fragment,
  useEffect,
  useState,
  useContext,
  useCallback,
} from "react";
import Administrative from "./Administrative";
import axios from "axios";
import { awsUrl2 } from "../../../config";
import PricingTableSummary from "./PricingTableSummary";
import UnbundledRate from "./UnbundledRate";
import PDFMerger from "pdf-merger-js/browser";
import SmallLoader from "../../../newcomponent/common/smallLoader";
import { LibertateContext } from "../../../context/Context";
import styles from "./FormViewProposal.module.scss";
import moment from "moment";
import DownloadEAcord from "../sections-quote/DownloadEAcord";
const FormViewProposal = ({ updatePopUpData }) => {
  let libertateContext = useContext(LibertateContext);
  const [administrativeData, setAdministrativeData] = useState({});
  const [administrativeDataLoader, setAdministrativeDataLoader] =
    useState(true);
  const [pricingTablesummaryDataLoader, setPricingTablesummaryDataLoader] =
    useState(true);
  const [unbundledRateDataLoader, setUnbundledRateDataLoader] = useState(true);
  const [downloadDocumnetloader, setDownloadDocumnetloader] = useState(false);
  const [saveResponseloader, setSaveResponseLoader] = useState(false);
  const [editedCompanyNameViewProposal, setEditedCompanyNameViewProposal] =
    useState("");
  const [comment, setComment] = useState(
    `Your account has been underwritten and approved by our insurance carrier. Our pricing is guaranteed and valid for 30 days. The client is committed to conducting an initial worksite safety evaluation with our safety and risk management team within the first thirty days of service commencement. Furthermore, the client will participate in quarterly safety reviews with our risk management team thereafter`
  );
  const [editedquoteGenratedDate, seteditedquoteGenratedDate] = useState("");

  const [companyNameError, setCompanyNameError] = useState(false);
  const [commentError, setCommentError] = useState(false);

  useEffect(() => {
    if (editedCompanyNameViewProposal?.length > 48) {
      setCompanyNameError(true);
    } else {
      setCompanyNameError(false);
    }
  }, [editedCompanyNameViewProposal]);

  useEffect(() => {
    if (comment?.length > 430) {
      setCommentError(true);
    } else {
      setCommentError(false);
    }
  }, [comment]);

  let currProspect = sessionStorage.getItem("currProspect");
  let quoteData = sessionStorage.getItem("quoteData");
  quoteData = JSON.parse(quoteData);
  let companyName = "",
    quoteGeneratedDate = "",
    quoteEffectiveDate = "",
    proposalId =
      libertateContext?.pricingToolData?.proposalNumber || "0000000000";
  try {
    currProspect = JSON.parse(currProspect);
    companyName = currProspect?.companyProfile?.companyName?.value;
    quoteGeneratedDate = quoteData?.["date"] || "";
    quoteEffectiveDate = currProspect?.companyProfile?.effectiveDate.value;
  } catch (error) {
    console.log("++error", error);
  }

  useEffect(() => {
    seteditedquoteGenratedDate(quoteGeneratedDate);
  }, [quoteGeneratedDate]);

  useEffect(() => {
    if (libertateContext && libertateContext.pricingToolData) {
      try {
        let peoCharges = libertateContext?.pricingToolData?.peoCharges;
        let editedCompanyNamePresent =
          libertateContext?.pricingToolData?.editedCompanyNameViewProposal ||
          companyName;
        setAdministrativeData(peoCharges);
        setPricingTablesummaryDataLoader(false);
        setUnbundledRateDataLoader(false);
        setAdministrativeDataLoader(false);
        setEditedCompanyNameViewProposal(editedCompanyNamePresent);
      } catch (error) {
        console.error("An error occurred while processing data:", error);
      }
    }
  }, [libertateContext.pricingToolData]);

  const setAdministrativeDataHandler = useCallback(
    (tableName, questionId, newValue) => {
      setAdministrativeData((prevState) => ({
        ...prevState,
        [tableName]: prevState[tableName].map((question) =>
          question.questionId === questionId
            ? { ...question, response: newValue }
            : question
        ),
      }));
    },
    []
  );
  const saveResponseHandler = () => {
    try {
      setSaveResponseLoader(true);
      let user_email_id = sessionStorage.getItem("user_id");
      let quoteData = sessionStorage.getItem("quoteData");
      axios
        .post(
          awsUrl2 + "/api/savePeoCharges",
          JSON.stringify({
            user_email_id,
            quoteData,
            peoCharges: administrativeData,
            editedCompanyNameViewProposal,
          })
        )
        .then((res) => {
          console.log(res);
          setSaveResponseLoader(false);
        })
        .catch((error) => {
          console.log(error);
          setSaveResponseLoader(false);
        });
    } catch (error) {
      console.log(error);
    }
  };

  function convertToTimestamp(dateInput) {
    if (typeof dateInput === "string" && !isNaN(Number(dateInput))) {
      return dateInput;
    }

    // If not a number, create a Date object and get the timestamp
    const date = new Date(dateInput);
    const timestampMs = date.getTime();

    return timestampMs;
  }
  console.log(editedquoteGenratedDate, "editedquoteGenratedDate");
  const timestampMs = convertToTimestamp(editedquoteGenratedDate);

  const downloadViewProposalHandler = async (
    companyName,
    editedCompanyNameViewProposal,
    quoteGeneratedDate,
    quoteEffectiveDate,
    proposalId
  ) => {
    try {
      setDownloadDocumnetloader(true);
      const response = await axios.post(
        awsUrl2 + "/api/generateViewProposalPdf",
        JSON.stringify({
          administrativeData,
          pricingTablesummaryData:
            libertateContext?.pricingToolData?.priceIndicationData
              ?.classCodeTableData,
          unbundledRateData:
            libertateContext?.pricingToolData?.priceIndicationData
              ?.classCodeTableData,
          sutaTypeMap: libertateContext?.pricingToolData?.sutaTypeMap,
          companyName,
          editedCompanyNameViewProposal,
          timestampMs,
          quoteEffectiveDate,
          proposalId,
          selectedCarrier: libertateContext?.pricingToolData?.selectedCarrier,
          comment,
        })
      );
      if (response?.data?.streamData && response?.data?.pricingSummaryData) {
        // Decode the base64 string back into binary data
        const binaryStreamData = atob(response?.data.streamData);
        // Convert the binary data into a Uint8Array
        const byteStreamArray = new Uint8Array(binaryStreamData.length);
        for (let i = 0; i < binaryStreamData.length; i++) {
          byteStreamArray[i] = binaryStreamData.charCodeAt(i);
        }
        const binaryPricingSummaryData = atob(
          response?.data.pricingSummaryData
        );
        const bytePricingSummaryArray = new Uint8Array(
          binaryPricingSummaryData.length
        );
        for (let i = 0; i < binaryPricingSummaryData.length; i++) {
          bytePricingSummaryArray[i] = binaryPricingSummaryData.charCodeAt(i);
        }
        const streamblob = new Blob([byteStreamArray], {
          type: "application/pdf",
        });
        const pricingSummaryblob = new Blob([bytePricingSummaryArray], {
          type: "application/pdf",
        });
        let mergedPdfBlob = await mergePdf([streamblob, pricingSummaryblob]);
        // Create a URL for the Blob and open it
        const url = URL.createObjectURL(mergedPdfBlob);
        // Create an anchor element
        const a = document.createElement("a");
        a.href = url;

        let currProspect = JSON.parse(sessionStorage.getItem("currProspect"));
        let documentName = `__AltMkt_INNO_Proposal_${
          currProspect?.companyProfile?.companyName.value
        } "(PROPOSAL 20240502060)_${moment
          .unix(quoteGeneratedDate / 1000)
          .format("MM/DD/YYYY")}_ver1`;
        a.download = `${documentName}.pdf`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        setDownloadDocumnetloader(false);
        // Revoke the object URL after some time to free up memory
        setTimeout(() => URL.revokeObjectURL(url), 1000);
      } else {
        console.error("No PDF data found in the response.");
      }
    } catch (error) {
      console.error("Error generating or merging PDF:", error);
      setDownloadDocumnetloader(false);
    }
  };

  const onCommentChange = (e) => {
    setComment(e.target.value);
  };

  // PDF merging function
  const mergePdf = async (files) => {
    try {
      const merger = new PDFMerger();

      for (const file of files) {
        if (!(file instanceof Blob)) {
          console.error("Invalid PDF file:", file);
          continue;
        }
        await merger.add(file);
      }

      return await merger.saveAsBlob();
    } catch (error) {
      console.error("Error merging PDF files:", error);
      return null;
    }
  };

  return (
    <Fragment>
      <div id="viewProposal">
        <div
          className="compName"
          style={{
            fontSize: "20px",
            fontWeight: "600",
            color: "#2c2e33",
            marginTop: "35px",
          }}
        >
          <p
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginRight: "1.5rem",
            }}
          >
            {companyName}
          </p>
        </div>
        <div className="sectionContent1">
          <div>
            <div className="pink-header m-0 p-0 mb-3 bg-white">
              <div className="font-family-montserrat-bold headings">
                PEO Charges
              </div>
            </div>
          </div>
          <div>
            {administrativeData &&
              Object.keys(administrativeData).length > 0 && (
                <Fragment>
                  <Administrative
                    key={"administrativeServicekey"}
                    administrativeData={
                      administrativeData?.["administrativeServicekey"]
                    }
                    tableName={"administrativeServicekey"}
                    HeadingValue={"Administration Service Charges"}
                    setAdministrativeData={setAdministrativeDataHandler}
                    administrativeDataLoader={administrativeDataLoader}
                  />
                  <Administrative
                    key={"optionalAdditionalServiceKey"}
                    administrativeData={
                      administrativeData?.["optionalAdditionalServiceKey"]
                    }
                    tableName={"optionalAdditionalServiceKey"}
                    HeadingValue={"Optional Service Administration Charges"}
                    setAdministrativeData={setAdministrativeDataHandler}
                    administrativeDataLoader={administrativeDataLoader}
                  />
                  <Administrative
                    key={"programDetailsAdditionalServiceKey"}
                    administrativeData={
                      administrativeData?.["programDetailsAdditionalServiceKey"]
                    }
                    tableName={"programDetailsAdditionalServiceKey"}
                    HeadingValue={"Program Details"}
                    setAdministrativeData={setAdministrativeDataHandler}
                    administrativeDataLoader={administrativeDataLoader}
                  />
                </Fragment>
              )}

            {administrativeDataLoader && (
              <div className="d-flex justify-content-center">
                <SmallLoader />
              </div>
            )}
          </div>
          {!administrativeDataLoader && (
            <div className="d-flex justify-content-center mb-4 mt-4">
              <button
                onClick={saveResponseHandler}
                className="btn btn-lg btnSubmits"
              >
                Save Response
                {saveResponseloader && (
                  <span>
                    <SmallLoader />
                  </span>
                )}
              </button>
            </div>
          )}

          <div>
            {/* prcing indication preview */}
            <div id="priceIndicationPreview">
              <div className=" mt-5">
                <div className="pink-header m-0 p-0 mb-3 bg-white">
                  <div className="font-family-montserrat-bold headings">
                    Price Indication Preview
                  </div>
                </div>
              </div>

              <div style={{ padding: "0px 15px" }}>
                {/*pricing summary*/}
                <div>
                  <PricingTableSummary
                    styles={styles}
                    data={
                      libertateContext?.pricingToolData?.priceIndicationData
                        ?.classCodeTableData
                    }
                    selectedCarrier={
                      libertateContext?.pricingToolData?.selectedCarrier
                    }
                    sutaTypeMap={libertateContext?.pricingToolData?.sutaTypeMap}
                    proposalNumber={
                      libertateContext?.pricingToolData?.proposalNumber || ""
                    }
                    companyName={companyName}
                    editedCompanyNameViewProposal={
                      editedCompanyNameViewProposal
                    }
                    setEditedCompanyNameViewProposal={
                      setEditedCompanyNameViewProposal
                    }
                    editedquoteGenratedDate={editedquoteGenratedDate}
                    seteditedquoteGenratedDate={seteditedquoteGenratedDate}
                    companyNameError={companyNameError}
                  />
                </div>
                {/* end pricing summary*/}

                <div class="comment-box">
                  <textarea
                    id="comment"
                    style={{ width: "100%", height: "80px" }}
                    value={comment}
                    onChange={(e) => {
                      if (e.target.value.length <= 431) {
                        onCommentChange(e);
                      }
                    }}
                    maxLength={431}
                  >
                    {comment}
                  </textarea>
                </div>

                {commentError && (
                  <div>
                    {/* Your content here for when the word count exceeds 60 */}
                    <p className={`${styles.dynamicComment} dynamicComment `}>
                      Comment exceeds 430 characters.
                    </p>
                  </div>
                )}

                {/* unbundled Rate*/}
                <div>
                  <UnbundledRate
                    styles={styles}
                    data={
                      libertateContext?.pricingToolData?.priceIndicationData
                        ?.classCodeTableData
                    }
                    selectedCarrier={
                      libertateContext?.pricingToolData?.selectedCarrier
                    }
                    sutaTypeMap={libertateContext?.pricingToolData?.sutaTypeMap}
                  />
                </div>

                {/* unbundled Rate*/}
              </div>
            </div>

            <div className={styles.alignbtn}>
              {!pricingTablesummaryDataLoader && !unbundledRateDataLoader && (
                <div className={styles.loaderPosition}>
                  <div>
                    <button
                      className="btn btn-lg btnSubmits"
                      onClick={() =>
                        downloadViewProposalHandler(
                          companyName,
                          editedCompanyNameViewProposal,
                          quoteGeneratedDate,
                          quoteEffectiveDate,
                          proposalId
                        )
                      }
                      disabled={companyNameError || commentError}
                    >
                      Print Proposal Doc
                    </button>
                  </div>

                  {downloadDocumnetloader && (
                    <div>
                      <span>
                        <SmallLoader />
                      </span>
                    </div>
                  )}
                </div>
              )}

              <DownloadEAcord
                selectedCarrier={
                  libertateContext?.pricingToolData?.selectedCarrier || ""
                }
                stateTableData={
                  libertateContext?.pricingToolData?.modifiersData
                    ?.stateTableData || {}
                }
                updatePopUpData={updatePopUpData}
              />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default FormViewProposal;
