import axios from "axios";
import React, { useEffect, useState } from "react";
import { awsUrl2 } from "../../config";
import LoadingOverlay from "react-loading-overlay";

function ConfirmDealButton(props) {
  const {
    rowData,
    isConfirmed,
    updatePopUpData,
    updateConfirmDealMap,
    isWon,
    isLost,
    handleDealConfirm,
  } = props;
  let { user_email_id, quoteData, address } = rowData;
  const [isLoading, setIsLoading] = useState(false);

  let handleClick = async () => {
    updatePopUpData({
      show: true,
      title: "Do you wish to confirm this deal?",
      children: (
        <>
          <br></br>
          <span style={{ fontWeight: "bold" }}>
            This action will move the submission to the respective tab.
          </span>
        </>
      ),
      acceptBtn: "Yes",
      acceptBtnCallback: async () => {
        try {
          let userRequestBody = {
            user_email_id,
            deal_confirmed: true,
          };
          setIsLoading(true);

          let promiseList = [
            axios.post(awsUrl2 + "/api/userTableData/update", userRequestBody),
          ];
          if (quoteData) {
            let { uuid, date } = quoteData;
            let quoteRequestBody = {
              user_email_id,
              timestamp: date,
              peo: "ies",
              address,
              uuid,
              deal_confirmed: true,
            };
            promiseList.push(
              axios.post(
                awsUrl2 + "/api/updateUserStatusTable",
                quoteRequestBody
              )
            );
          }

          await Promise.all(promiseList).then((res) => {
            setIsLoading(false);
            if (updateConfirmDealMap) {
              updateConfirmDealMap(user_email_id);
            }
            if (handleDealConfirm) {
              let dealFlag = isWon ? true : isLost ? false : "";
              handleDealConfirm(rowData, dealFlag);
            }
          });
        } catch (e) {
          console.error("Error while updating", e);
        }
      },
    });
  };
  return (
    <>
      <LoadingOverlay active={isLoading}>
        <button
          style={{
            fontSize: "12px",
            backgroundColor: "#FFCD4D",
            borderRadius: "4px",
            border: "1px solid #FFCD4D",
          }}
          onClick={handleClick}
          disabled={
            isConfirmed ||
            (!isWon && !isLost) ||
            (rowData?.pricingStatus !== "Approved" &&
              rowData?.pricingStatus !== "Rejected" &&
              rowData?.pricingStatus !== "Submitted")
          }
        >
          Confirm
        </button>
      </LoadingOverlay>
    </>
  );
}

export default React.memo(ConfirmDealButton);
