import { tempURL, apiURL, localURL, awsUrl } from "../config";
import { setCookie } from "./session_data";
import { Auth } from "aws-amplify";
import axios from "axios";
import { awsUrl2 } from "../config";
import $ from "jquery";
import { from } from "zen-observable";
import { getUserAuthStatus, showErrAlert } from "./common";
window.jQuery = $;
window.$ = $;
global.jQuery = $;
require("bootstrap");

export async function signIn(e, loginPageHandler) {
  e.preventDefault();
  let validForm = validateForm();
  if (validForm) {
    $("#loader").css("display", "block");
    const username = $("#userId").val();
    const password = $("#userPassword").val();
    try {
      var userTemp = await Auth.signIn(username.toLowerCase(), password);

      console.log("UserTemp: ", userTemp);
      if (userTemp.signInUserSession !== null) {
        const userData = {
          email_id: username.toLowerCase(),
          stage: process.env.REACT_APP_MODE,
          client: "Libertate",
        };
        try {
          await axios.post(awsUrl2 + "/api/trackUserSession", userData);
          console.log("UserSessionManagement table is updated");
        } catch (error) {
          console.log("Error in updating the UserSessionManagement table");
        }
      }

      if (userTemp.challengeName === "NEW_PASSWORD_REQUIRED") {
        loginPageHandler("changePassword");
        $("#link").click();
        $("#loader").css("display", "none");
      } else {
        if (
          userTemp.signInUserSession !== null &&
          userTemp.signInUserSession.accessToken.payload[
            "cognito:groups"
          ].includes(process.env.REACT_APP_ADMIN_GROUP)
        ) {
          sessionStorage.setItem("isLoggedIn", true);
          window.location.href = "./Dashboard";
        } else if (
          userTemp.signInUserSession !== null &&
          userTemp.signInUserSession.accessToken.payload[
            "cognito:groups"
          ].includes(process.env.REACT_APP_AGENCY_GROUP)
        ) {
          // console.log(
          //   "User Role",
          //   userTemp.signInUserSession.accessToken.payload["cognito:groups"]
          // );
          sessionStorage.setItem("isLoggedIn", true);
          window.location.href = "./Quote";
          $("#loader").css("display", "none");
        } else if (
          (userTemp.signInUserSession !== null &&
            (userTemp.signInUserSession.accessToken.payload[
              "cognito:groups"
            ].includes(process.env.REACT_APP_RIPPLING_GROUP) ||
              userTemp.signInUserSession.accessToken.payload[
                "cognito:groups"
              ].includes(process.env.REACT_APP_IES_GROUP) ||
              userTemp.signInUserSession.accessToken.payload[
                "cognito:groups"
              ].includes(process.env.REACT_APP_GMS_GROUP) ||
              userTemp.signInUserSession.accessToken.payload[
                "cognito:groups"
              ].includes(process.env.REACT_APP_SIMPLOY_GROUP) ||
              userTemp.signInUserSession.accessToken.payload[
                "cognito:groups"
              ].includes(process.env.REACT_APP_DEMO_GROUP) ||
              userTemp.signInUserSession.accessToken.payload[
                "cognito:groups"
              ].includes(process.env.REACT_APP_EMPLOYCO_GROUP) ||
              userTemp.signInUserSession.accessToken.payload[
                "cognito:groups"
              ].includes(process.env.REACT_APP_APPPEO_GROUP) ||
              userTemp.signInUserSession.accessToken.payload[
                "cognito:groups"
              ].includes(process.env.REACT_APP_ENGAGEPEO_GROUP) ||
              userTemp.signInUserSession.accessToken.payload[
                "cognito:groups"
              ].includes(process.env.REACT_APP_MAKAI_GROUP) ||
              userTemp.signInUserSession.accessToken.payload[
                "cognito:groups"
              ].includes(process.env.REACT_APP_WCN_GROUP) ||
              userTemp.signInUserSession.accessToken.payload[
                "cognito:groups"
              ].includes(process.env.REACT_APP_CORPSOL_GROUP) ||
              userTemp.signInUserSession.accessToken.payload[
                "cognito:groups"
              ].includes(process.env.REACT_APP_GREGORY_GROUP) ||
              userTemp.signInUserSession.accessToken.payload[
                "cognito:groups"
              ].includes(process.env.REACT_APP_EASI_GROUP) ||
              userTemp.signInUserSession.accessToken.payload[
                "cognito:groups"
              ].includes(process.env.REACT_APP_STAFFLINK_GROUP) ||
              userTemp.signInUserSession.accessToken.payload[
                "cognito:groups"
              ].includes(process.env.REACT_APP_STRATUS_GROUP))) ||
          userTemp.signInUserSession.accessToken.payload[
            "cognito:groups"
          ].includes(process.env.REACT_APP_EXPRESS_GROUP) ||
          userTemp.signInUserSession.accessToken.payload[
            "cognito:groups"
          ].includes(process.env.REACT_APP_GALACTIC_GROUP) ||
          userTemp.signInUserSession.accessToken.payload[
            "cognito:groups"
          ].includes(process.env.REACT_APP_PAYROLLME_GROUP) ||
          userTemp.signInUserSession.accessToken.payload[
            "cognito:groups"
          ].includes(process.env.REACT_APP_ACCURATE_GROUP) ||
          userTemp.signInUserSession.accessToken.payload[
            "cognito:groups"
          ].includes(process.env.REACT_APP_CONNECTIFY) ||
          userTemp.signInUserSession.accessToken.payload[
            "cognito:groups"
          ].includes(process.env.REACT_APP_MANAGEPOINT) ||
          userTemp.signInUserSession.accessToken.payload[
            "cognito:groups"
          ].includes(process.env.REACT_APP_NEMR) ||
          userTemp.signInUserSession.accessToken.payload[
            "cognito:groups"
          ].includes(process.env.REACT_APP_TEAMWORKS) ||
          userTemp.signInUserSession.accessToken.payload[
            "cognito:groups"
          ].includes(process.env.REACT_APP_ELEVATION) ||
          userTemp.signInUserSession.accessToken.payload[
            "cognito:groups"
          ].includes(process.env.REACT_APP_TOTALSOLUTION) ||
          userTemp.signInUserSession.accessToken.payload[
            "cognito:groups"
          ].includes(process.env.REACT_APP_COVENANT) ||
          userTemp.signInUserSession.accessToken.payload[
            "cognito:groups"
          ].includes(process.env.REACT_APP_METHOD_GROUP) ||
          userTemp.signInUserSession.accessToken.payload[
            "cognito:groups"
          ].includes(process.env.REACT_APP_MILTONPARK) ||
          userTemp.signInUserSession.accessToken.payload[
            "cognito:groups"
          ].includes(process.env.REACT_APP_SIDECAR) ||
          userTemp.signInUserSession.accessToken.payload[
            "cognito:groups"
          ].includes(process.env.REACT_APP_CBR) ||
          userTemp.signInUserSession.accessToken.payload[
            "cognito:groups"
          ].includes(process.env.REACT_APP_ESG_GROUP) ||
          userTemp.signInUserSession.accessToken.payload[
            "cognito:groups"
          ].includes(process.env.REACT_APP_HIGH_ROAD_GROUP) ||
          userTemp.signInUserSession.accessToken.payload[
            "cognito:groups"
          ].includes(process.env.REACT_APP_MATHEWBROWN_GROUP) ||
          userTemp.signInUserSession.accessToken.payload[
            "cognito:groups"
          ].includes(process.env.REACT_APP_WORKCOMPNOW) ||
          userTemp.signInUserSession.accessToken.payload[
            "cognito:groups"
          ].includes(process.env.REACT_APP_FINGERCHECK) ||
          userTemp.signInUserSession.accessToken.payload[
            "cognito:groups"
          ].includes(process.env.REACT_APP_ZYNERGIAHR) ||
          userTemp.signInUserSession.accessToken.payload[
            "cognito:groups"
          ].includes(process.env.REACT_APP_PRESTIGE)
        ) {
          // console.log(
          //   "User Role",
          //   userTemp.signInUserSession.accessToken.payload["cognito:groups"]
          // );
          sessionStorage.setItem("isLoggedIn", true);
          window.location.href = "./Quote";
          // $('#loader').css('display','none');
        } else if (
          userTemp.signInUserSession !== null &&
          userTemp.signInUserSession.accessToken.payload[
            "cognito:groups"
          ].includes(process.env.REACT_APP_SALES_GROUP)
        ) {
          sessionStorage.setItem("isLoggedIn", true);
          window.location.href = "./Quote";
        } else if (
          userTemp.signInUserSession !== null &&
          userTemp.signInUserSession.accessToken.payload[
            "cognito:groups"
          ].includes(process.env.REACT_APP_UW_GROUPS)
        ) {
          sessionStorage.setItem("isLoggedIn", true);
          window.location.href = "./UwPortal";
        } else {
          try {
            sessionStorage.clear();
            localStorage.clear();
          } catch (error) { }
          Auth.signOut()
            .then(function (res) { })
            .catch((err) => {
              console.log("error in logout: ", err);
            });
          let errMsg;
          $("#loader").css("display", "none");
          errMsg = "User does not exist!";
          $("#loginSubmit")
            .siblings(".errMsg")
            .html("<br /> " + errMsg);
        }
        // console.log("In loged in page: ", userTemp);
      }
    } catch (err) {
      let errMsg;
      $("#loader").css("display", "none");
      console.log("error signing in! :", err);
      if (
        err.code === "UserNotFoundException" ||
        err.code === "NotAuthorizedException"
      ) {
        errMsg = err.message;
      } else {
        errMsg =
          "Unable to login! Please contact admin@insurecomp.com for more details!";
      }
      $("#loginSubmit")
        .siblings(".errMsg")
        .html("<br /> " + errMsg);
    }
  }
}

function validateForm() {
  var form = true;
  var emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  var userIdValue = $("#userId").val();
  var userPasswordValue = $("#userPassword").val();

  // Validate Email
  if (userIdValue === "") {
    $("#userId").css("border-color", "rgb(255, 0, 0)");
    $("#userId").siblings(".errMsg").html("Email is required");
    $("#userId").focus();
    form = false;
  } else if (!emailPattern.test(userIdValue)) { // Check for email format
    $("#userId").css("border-color", "rgb(255, 0, 0)");
    $("#userId").siblings(".errMsg").html("Format: john.doe@example.com");
    $("#userId").focus();
    form = false;
  } else {
    $("#userId").css("border-color", "");
    $("#userId").siblings(".errMsg").html("");
  }

  // Validate Password
  if (userPasswordValue === "") {
    $("#userPassword").css("border-color", "rgb(255, 0, 0)");
    $("#userPassword").siblings(".errMsg").html("Password is required");
    $("#userPassword").focus();
    form = false;
  } else {
    $("#userPassword").css("border-color", "");
    $("#userPassword").siblings(".errMsg").html("");
  }

  return form;
}




