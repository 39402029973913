import { ENDPOINTS } from "../constants/Endpoints";
import axiosInstance from "../middleware/AxiosInstance";

class CommonApi {

    async userTableData(userDetails, type) {
        try {
          const response = await axiosInstance.post(
            `${ENDPOINTS.USER_TABLE_DATA}/${type}`,
            userDetails
          );
          console.log('response', response)
          return response;
        } catch (error) {
          console.error("Error in API request:", error);
          throw new Error("Error in API request: " + error);
        }
      }
}

export default new CommonApi()