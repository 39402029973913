import axios from "axios";
import { awsUrl, awsUrl2 } from "../config";

import PDFMerger from "pdf-merger-js/browser";

import moment from "moment";

const RESPONSE_JSON = require("./syncWithSalesforceResponse.json");
const CARRIER_LIST = require("./carrierList.json");
const comment =
  "Your account has been underwritten and approved by our insurance carrier. Our pricing is guaranteed and valid for 30 days. The client is committed to conducting an initial worksite safety evaluation with our safety and risk management team within the first thirty days of service commencement. Furthermore, the client will participate in quarterly safety reviews with our risk management team thereafter";

const getPricingData = async (user_id, quoteData) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        awsUrl2 + "/api/getPricingData",
        JSON.stringify({
          userId: user_id,
          quoteData,
        })
      )
      .then(async (response) => {
        let dataFromAPI = response.data;

        resolve(dataFromAPI);
      })
      .catch((err) => {
        console.log("error: ", err);

        reject();
      });
  });
};

const isNumAndConvert = (num) => {
  if (isNaN(num)) return num;

  return (Math.round(Number(num) * 100) / 100).toFixed(2);
};

const getInsurecompData = (data) => {
  let response = JSON.parse(JSON.stringify(RESPONSE_JSON)).insureComp;

  try {
    let selectedCarrier = data?.overridesMap?.selectedCarrier;

    //mapping values
    let _profitabilityData =
      data?.["computationData"]?.["profitabilityData"]?.[selectedCarrier];
    let _otherFactors =
      data?.["computationData"]?.["otherFactors"]?.[selectedCarrier];
    let _summaryData = data?.["summaryData"];

    response["accountId"] = data?.accountId ?? null;

    response["adminProfit"] =
      isNumAndConvert(_profitabilityData?.["adminProfit"]?.value) ?? null;
    response["adminProfitPercent"] =
      isNumAndConvert(_profitabilityData?.["adminProfit"]?.percentage) ?? null;

    response["grossProfitAmount"] =
      isNumAndConvert(_profitabilityData?.["grossProfit"]?.value) ?? null;
    response["grossProfitPercent"] =
      isNumAndConvert(_profitabilityData?.["grossProfit"]?.percentage) ?? null;

    response["netProfit"] =
      isNumAndConvert(_profitabilityData?.["netProfit"]?.value) ?? null;
    response["netProfitPercent"] =
      isNumAndConvert(_profitabilityData?.["netProfit"]?.percentage) ?? null;

    response["suiProfit"] =
      isNumAndConvert(_profitabilityData?.["sutaProfit"]?.value) ?? null;
    response["suiProfitPercent"] =
      isNumAndConvert(_profitabilityData?.["sutaProfit"]?.percentage) ?? null;

    response["wcProfit"] =
      isNumAndConvert(_profitabilityData?.["wcProfit"]?.value) ?? null;
    response["wcProfitPercent"] =
      isNumAndConvert(_profitabilityData?.["wcProfit"]?.percentage) ?? null;

    response["billableAdmin"] =
      isNumAndConvert(_otherFactors?.["totalBillableAdmin"]) ?? null;

    response["cashInFlowOutFlow"] =
      isNumAndConvert(_otherFactors?.["cashFlow"]) ?? null;

    response["commission"] =
      isNumAndConvert(_otherFactors?.["totalCommission"]) ?? null;

    response["totalAdminAmount"] =
      isNumAndConvert(_summaryData?.["adminData"]?.["totalAdminValue"]) ?? null;

    response["totalAdminPercent"] =
      isNumAndConvert(_summaryData?.["adminData"]?.["totalAdminPercent"]) ??
      null;

    response["totalBillRate"] =
      isNumAndConvert(_otherFactors?.["totalBillRate"]) ?? null;

    response["totalSUTA"] =
      isNumAndConvert(_summaryData?.["sutaData"]?.["totalSuta"]) ?? null;

    response["lossFund"] =
      isNumAndConvert(_otherFactors?.["totalLossFund"]) ?? null;

    response["wcCost"] =
      isNumAndConvert(_otherFactors?.["totalWcCost"]) ?? null;

    for (let carrier of data?.carrierList || []) {
      response[`billableWC${CARRIER_LIST.carrierMapping[carrier]}`] =
        isNumAndConvert(_summaryData?.["totalWcData"]?.[carrier]?.billableWc) ??
        null;

      response[`payroll${CARRIER_LIST.carrierMapping[carrier]}`] =
        isNumAndConvert(_summaryData?.["totalWcData"]?.[carrier]?.payroll) ??
        null;
    }

    response["adminPercent"] =
      data?.overridesMap?.carrierIndependent?.["overridesType"]?.["admin"] ===
      "overall"
        ? isNumAndConvert(
            data?.overridesMap?.carrierIndependent?.["overallModifiers"]?.[
              "admin"
            ]
          ) ?? null
        : null;

    response["sutaMarkup"] =
      data?.overridesMap?.carrierIndependent?.["overridesType"]?.[
        "sutaMarkup"
      ] === "overall"
        ? isNumAndConvert(
            data?.overridesMap?.carrierIndependent?.["overallModifiers"]?.[
              "sutaMarkup"
            ]
          ) ?? null
        : null;
  } catch (error) {
    console.log("Error in get Insurecomp: ", error);
  }
  return response;
};

const getSubmissionCommisions = (data) => {
  let response = [];
  let SUBMISSION_COMMISSION = JSON.parse(JSON.stringify(RESPONSE_JSON))
    .submissionCommisions?.[0];

  try {
    let selectedCarrier = data?.overridesMap?.selectedCarrier ?? null;

    let overallModifiersBrokerList = data?.["modifiersData"]?.["brokerList"];

    let _overrideCarrierMap =
      data?.["overridesMap"]?.["carrierDependent"]?.[selectedCarrier];

    if (_overrideCarrierMap?.["overallModifiers"]?.["commission"] !== "no") {
      for (let broker of _overrideCarrierMap?.["overallModifiers"]?.[
        "brokerList"
      ] ?? []) {
        let _SUBMISSION_COMMISSION = JSON.parse(
          JSON.stringify(SUBMISSION_COMMISSION)
        );

        _SUBMISSION_COMMISSION.name = getBrokerName(
          overallModifiersBrokerList,
          broker.name
        );

        _SUBMISSION_COMMISSION.payrollOrPremium = broker.type;

        _SUBMISSION_COMMISSION.value = broker.value;

        response.push(_SUBMISSION_COMMISSION);
      }
    }
  } catch (error) {
    console.log(error);
  }

  return response;
};

const getModifier = (data) => {
  let response = JSON.parse(JSON.stringify(RESPONSE_JSON)).modifier;
  let EMPTY_STATE_DATA_ROW = JSON.parse(JSON.stringify(response))
    ?.stateData?.[0];

  response["stateData"] = [];

  try {
    let selectedCarrier = data?.overridesMap?.selectedCarrier ?? null;

    let _overrideOverallModifier =
      data?.["overridesMap"]?.["carrierDependent"]?.[selectedCarrier]?.[
        "overallModifiers"
      ];
    let _modifiersData = data?.["modifiersData"]?.["stateTableData"];

    response["accountId"] = data?.accountId ?? null;

    response["carrier"] = CARRIER_LIST.carrierMapping[selectedCarrier];

    response["agentUpsell"] =
      isNumAndConvert(_overrideOverallModifier?.["agentUpsell"]) ?? null;

    response["deductibleDiscount"] =
      isNumAndConvert(_overrideOverallModifier?.["deductibleDiscount"]) ?? null;

    // response["Agent_Upsell"] = _overrideOverallModifier?.["agentUpsell"] ?? null;

    response["discount"] =
      isNumAndConvert(_overrideOverallModifier?.["discount"]) ?? null;

    for (let state in _modifiersData) {
      let _EMPTY_STATE_DATA_ROW = JSON.parse(
        JSON.stringify(EMPTY_STATE_DATA_ROW)
      );

      _EMPTY_STATE_DATA_ROW["admin"] =
        isNumAndConvert(_modifiersData?.[state]?.stateBillableAdmin) ?? null;

      _EMPTY_STATE_DATA_ROW["adminPercent"] =
        data?.overridesMap?.carrierIndependent?.["overridesType"]?.["admin"] ===
        "state"
          ? isNumAndConvert(_modifiersData?.[state]?.overallAdminRate) ?? null
          : null;

      _EMPTY_STATE_DATA_ROW["markUp"] =
        data?.overridesMap?.carrierIndependent?.["overridesType"]?.[
          "sutaMarkup"
        ] === "state"
          ? isNumAndConvert(_modifiersData?.[state]?.sutaMarkup) ?? null
          : null;

      _EMPTY_STATE_DATA_ROW["newSUTA"] =
        isNumAndConvert(_modifiersData?.[state]?.newSuta) ?? null;

      _EMPTY_STATE_DATA_ROW["state"] =
        isNumAndConvert(_modifiersData?.[state]?.state?.toUpperCase()) ?? null;

      _EMPTY_STATE_DATA_ROW["suta"] =
        isNumAndConvert(_modifiersData?.[state]?.suta) ?? null;

      _EMPTY_STATE_DATA_ROW["sutaProfit"] =
        isNumAndConvert(_modifiersData?.[state]?.sutaMargin) ?? null;

      _EMPTY_STATE_DATA_ROW["emod"] =
        isNumAndConvert(_modifiersData?.[state]?.emod) ?? null;

      response["stateData"].push(_EMPTY_STATE_DATA_ROW);
    }
  } catch (error) {
    console.log(error);
  }

  return response;
};

const getPriceIndication = (data) => {
  let response = [];
  const EMPTY_PRICING_ROW = JSON.parse(JSON.stringify(RESPONSE_JSON))[
    "priceIndication"
  ][0];

  try {
    let selectedCarrier = data?.overridesMap?.selectedCarrier;
    let clientWcPrice =
      data?.overridesMap?.carrierDependent?.[selectedCarrier]
        ?.classCodeModifiers?.clientWcPrice ?? {};

    for (let state in data.priceIndicationData.classCodeTableData[
      selectedCarrier
    ]) {
      for (let cc_row of data.priceIndicationData.classCodeTableData[
        selectedCarrier
      ][state]) {
        let _EMPTY_PRICING_ROW = JSON.parse(JSON.stringify(EMPTY_PRICING_ROW));

        _EMPTY_PRICING_ROW.accountId = data?.accountId ?? null;

        _EMPTY_PRICING_ROW.admin = isNumAndConvert(cc_row?.admin) ?? null;

        _EMPTY_PRICING_ROW.blendedRate =
          isNumAndConvert(cc_row?.blendedRate) ?? null;

        _EMPTY_PRICING_ROW.description = cc_row?.description ?? null;

        _EMPTY_PRICING_ROW.difference =
          isNumAndConvert(cc_row?.difference) ?? null;

        _EMPTY_PRICING_ROW.fica = isNumAndConvert(cc_row?.fica) ?? null;

        _EMPTY_PRICING_ROW.futa = isNumAndConvert(cc_row?.futa) ?? null;

        _EMPTY_PRICING_ROW.suta = isNumAndConvert(cc_row?.suta) ?? null;

        _EMPTY_PRICING_ROW.manualWCRate =
          isNumAndConvert(cc_row?.manualWcRate) ?? null;

        _EMPTY_PRICING_ROW.modifiedWCRate =
          isNumAndConvert(cc_row?.weightedRate) ?? null;

        _EMPTY_PRICING_ROW.netWCAdminRate =
          isNumAndConvert(cc_row?.netWcRateWithAdmin) ?? null;

        _EMPTY_PRICING_ROW.classCode = cc_row?.classCode ?? null;

        _EMPTY_PRICING_ROW.toolNetWCRate =
          isNumAndConvert(cc_row?.netWcRateTool) ?? null;

        _EMPTY_PRICING_ROW.totalTax = isNumAndConvert(cc_row?.totalTax) ?? null;

        _EMPTY_PRICING_ROW.carrier =
          CARRIER_LIST.carrierMapping[selectedCarrier] ?? null;

        _EMPTY_PRICING_ROW.state = cc_row?.state?.toUpperCase() ?? null;

        _EMPTY_PRICING_ROW.finalNetWCRate =
          isNumAndConvert(
            clientWcPrice?.[cc_row?.state]?.[_EMPTY_PRICING_ROW.stateCC]
          ) ??
          isNumAndConvert(cc_row.netWcRate) ??
          null;

        response.push(_EMPTY_PRICING_ROW);
      }
    }
  } catch (error) {
    console.log(error);
  }

  return response;
};

const convertKeysToCamelCase = (_data) => {
  if (_data && typeof _data === "object") {
    let __data = {};

    for (let _key in _data) {
      //recursive call to correct inner keys
      let _val = convertKeysToCamelCase(_data[_key]);

      //logic for new key
      let _key_temp = _key?.split("_")?.join("") ?? _key;
      let _new_key = _key_temp[0].toLowerCase() + _key_temp.substring(1);

      __data[_new_key] = _val;
    }

    return __data;
  }
  return _data;
};

const getBrokerName = (list, id) => {
  let response = "";
  try {
    let value = list?.find((element) => element.id === id);

    response = value.name;
  } catch (error) {
    console.log("error in getBrokerName: ", error);
  }

  return response;
};

const mergePdf = async (files) => {
  try {
    const merger = new PDFMerger();

    for (const file of files) {
      if (!(file instanceof Blob)) {
        console.error("Invalid PDF file:", file);
        continue;
      }
      await merger.add(file);
    }

    return await merger.saveAsBlob();
  } catch (error) {
    console.error("Error merging PDF files:", error);
    return null;
  }
};

const getPdfData = async (
  pricing_data,
  companyName,
  quoteGeneratedDate,
  quoteEffectiveDate
) => {
  try {
    const response = await axios.post(
      awsUrl2 + "/api/generateViewProposalPdf",
      JSON.stringify({
        administrativeData: pricing_data?.peoCharges,
        pricingTablesummaryData:
          pricing_data?.priceIndicationData?.classCodeTableData,
        unbundledRateData:
          pricing_data?.priceIndicationData?.classCodeTableData,
        sutaTypeMap: pricing_data?.sutaTypeMap,
        companyName,
        quoteEffectiveDate,
        proposalId: pricing_data?.proposalNumber || "0000000000",
        selectedCarrier: pricing_data?.overridesMap?.selectedCarrier,
        comment,
        timestampMs: quoteGeneratedDate,
      })
    );

    if (response?.data?.streamData && response?.data?.pricingSummaryData) {
      const binaryStreamData = atob(response?.data.streamData);

      const byteStreamArray = new Uint8Array(binaryStreamData.length);

      for (let i = 0; i < binaryStreamData.length; i++) {
        byteStreamArray[i] = binaryStreamData.charCodeAt(i);
      }
      const binaryPricingSummaryData = atob(response?.data.pricingSummaryData);

      const bytePricingSummaryArray = new Uint8Array(
        binaryPricingSummaryData.length
      );

      for (let i = 0; i < binaryPricingSummaryData.length; i++) {
        bytePricingSummaryArray[i] = binaryPricingSummaryData.charCodeAt(i);
      }

      const streamblob = new Blob([byteStreamArray], {
        type: "application/pdf",
      });

      const pricingSummaryblob = new Blob([bytePricingSummaryArray], {
        type: "application/pdf",
      });

      let mergedPdfBlob = await mergePdf([streamblob, pricingSummaryblob]);

      let key = `__AltMkt_INNO_Proposal_${companyName} "(PROPOSAL 20240502060)_${moment
        .unix(quoteGeneratedDate / 1000)
        .format("MM-DD-YYYY")}_ver1.pdf`;

      const response2 = await axios.post(awsUrl + "/api/quotePdfSignedURL", {
        key,
        type: "upload",
      });

      try {
        // axios upload was not working hence used fetch
        // seems like content type and body in axios are failing

        // await axios.put(response2.data.signedUrl, {
        //   data: mergedPdfBlob,
        // }, {
        //   headers: {
        //     'Content-Type': 'application/octet-stream'
        //   }
        // });

        await fetch(response2.data.signedUrl, {
          method: "PUT",
          headers: { "Content-Type": mergedPdfBlob.type },
          body: mergedPdfBlob,
        });

        console.log("File uploaded");

        const response3 = await axios.post(awsUrl + "/api/quotePdfSignedURL", {
          key,
          type: "get",
        });

        return response3.data.signedUrl;
      } catch (error) {
        console.log("Error in file upload", error);
      }
    }
  } catch (error) {
    console.log(error);
  }
};

const getYear = (date) => {
  return moment(date, "YYYY-MM-DD hh:mm:ss").year();
};

const getSummaryDataCalculations = async (data) => {
  let response = {};
  try {
    if (data?.pibitResponse?.key) {
      // Fetch the file
      await axios
        .post(
          awsUrl + "/api/uwDashboardFileDownload",
          JSON.stringify({
            fileName: data.pibitResponse.key,
            dataType: "summary",
          })
        )
        .then(async (res) => {
          let downloadURL = res.data;

          // Fetch json from presignedURL
          const dataone = await axios.get(downloadURL);

          if (dataone.data.length > 0) {
            // Calculations
            let min_eff_date_year = 9999,
              max_exp_date_year = -1;
            let total_incurred_loss = 0,
              average_incurred_loss = 0,
              highest_loss = 0,
              year_highest_loss = 0;

            for (let row of dataone?.data ?? []) {
              let _curr_eff_date_year = getYear(row.eff_date);
              let _curr_exp_date_year = getYear(row.exp_date);

              min_eff_date_year = Math.min(
                min_eff_date_year,
                _curr_eff_date_year
              );
              max_exp_date_year = Math.max(
                max_exp_date_year,
                _curr_exp_date_year
              );

              total_incurred_loss = total_incurred_loss + row.total_incurred;

              if (highest_loss < row.total_incurred) {
                highest_loss = row.total_incurred;
                year_highest_loss = _curr_eff_date_year;
              }
            }

            average_incurred_loss = total_incurred_loss / dataone.data.length;

            response = {
              totalIncurredLoss: isNumAndConvert(total_incurred_loss),
              averageIncurredLoss: isNumAndConvert(average_incurred_loss),
              highestLoss: isNumAndConvert(highest_loss),
              yearHighestLoss: String(year_highest_loss),
              yearFrom: String(min_eff_date_year),
              yearTo: String(max_exp_date_year),
            };
          }
        })
        .catch((err) => {
          console.log("error: ", err);
        });
    }
  } catch (error) {
    console.log("error in getSummaryDataCalculations: ", error);
  }

  return response;
};

export const fetchAdditionalDetails = async (
  user_id,
  quoteData,
  accountId,
  companyName,
  quoteGeneratedDate,
  quoteEffectiveDate
) => {
  let pricing_data = await getPricingData(user_id, quoteData);

  pricing_data.accountId = accountId;

  //Data mapping and changing into required format
  let insurecomp = getInsurecompData(pricing_data);
  let submissionCommisions = getSubmissionCommisions(pricing_data);
  let modifier = getModifier(pricing_data);
  let priceIndication = getPriceIndication(pricing_data);

  let proposalDocumentSignedUrl = await getPdfData(
    pricing_data,
    companyName,
    quoteGeneratedDate,
    quoteEffectiveDate
  );

  let summaryDataCalculations = await getSummaryDataCalculations(pricing_data);

  return {
    insurecomp,
    submissionCommisions,
    modifier,
    priceIndication,
    proposalDocumentSignedUrl,
    // selectedCarrier:
    //   CARRIER_LIST.carrierMapping[pricing_data?.overridesMap?.selectedCarrier],
    lossRunData: summaryDataCalculations,
  };
};
