import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { Auth } from "aws-amplify";

const AntiPrivateRoute = ({ children }) => {
  const [isLoggedIn, setLoggedInStatus] = useState(null);

  useEffect(() => {
    const checkLoggedInStatus = async () => {
      try {
        const sessionData = await Auth.currentAuthenticatedUser();
        if (sessionData) {
          setLoggedInStatus(true);
          sessionStorage.setItem("isLoggedInSingle", "true");
        }
      } catch (error) {
        setLoggedInStatus(false);
      }
    };
    checkLoggedInStatus();
  }, []);

  // If the user is logged in, redirect to the dashboard
  if (isLoggedIn) {
    return <Navigate to="/dashboard" />;
  }

  // If the user is not logged in, render the login page (or passed children)
  return children;
};

export default AntiPrivateRoute;
